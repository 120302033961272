<template>
  <div>
    <headerv-2></headerv-2>
    <!-- <FormulateInput type="text" />
	<RegisterForm></RegisterForm> -->
  <div class="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
    <div class="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
      <div class="flex flex-col overflow-y-auto md:flex-row">
        <div class="h-32 md:h-auto md:w-1/2">
          <img
            aria-hidden="true"
            class="object-cover w-full h-full dark:hidden"
            src="/images/banner.jpg"
            alt="Office"
          />
          <img
            aria-hidden="true"
            class="hidden object-cover w-full h-full dark:block"
            src="/images/banner.jpg"
            alt="Office"
          />
        </div>
        <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
          <div class="w-full">
            <!-- <h1 class="mb-4 text-lg font-semibold text-gray-700 dark:text-gray-200">
              CREATE ACCOUNT
            </h1> -->
            <form @submit.prevent="handleSubmit">
              <div class="block mt-4">
                <label class="inline-flex items-center">
                  <input
                    type="radio"
                    class="form-radio"
                    name="role"
                    value="lawfirm"
                    v-model="formData.role"
                  />
                  <span class="ml-2">Lawfirm</span>
                </label>
                <label class="inline-flex items-center ml-6">
                  <input
                    type="radio"
                    class="form-radio"
                    name="role"
                    value="advocate"
                    v-model="formData.role"
                  />
                  <span class="ml-2">Advocate</span>
                </label>
              </div>

              <div v-if="formData.role === 'lawfirm'" class="mt-4">
                <label class="block text-sm">
                  <span class="text-gray-700 dark:text-gray-400">Company Name</span>
                  <input
                    v-bind:class="['border p-3 rounded outline-none block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input', { 'border-red-500': errors.companyName }]"
                    type="text"
                    name="companyName"
                    v-model="formData.companyName"
                  />
                  <p v-if="errors.companyName" class="text-red-500 text-xs mt-1">{{ errors.companyName }}</p>
                </label>
              </div>

              <div v-if="formData.role === 'advocate'" class="mt-4">
                <label class="block text-sm">
                  <span class="text-gray-700 dark:text-gray-400">First Name</span>
                  <input
                    v-bind:class="['border p-3 rounded outline-none block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input', { 'border-red-500': errors.firstName }]"
                    type="text"
                    name="firstName"
                    v-model="formData.firstName"
                  />
                  <p v-if="errors.firstName" class="text-red-500 text-xs mt-1">{{ errors.firstName }}</p>
                </label>
                <label class="block mt-4 text-sm">
                  <span class="text-gray-700 dark:text-gray-400">Last Name</span>
                  <input
                    v-bind:class="['border p-3 rounded outline-none block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input', { 'border-red-500': errors.lastName }]"
                    type="text"
                    name="lastName"
                    v-model="formData.lastName"
                  />
                  <p v-if="errors.lastName" class="text-red-500 text-xs mt-1">{{ errors.lastName }}</p>
                </label>
              </div>

              <label class="block mt-4 text-sm">
                <span class="text-gray-700 dark:text-gray-400">Email</span>
                <input
                  v-bind:class="['border p-3 rounded outline-none block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input', { 'border-red-500': errors.email }]"
                  type="email"
                  name="email"
                  v-model="formData.email"
                />
                <p v-if="errors.email" class="text-red-500 text-xs mt-1">{{ errors.email }}</p>
              </label>

              <label class="block mt-4 text-sm">
                <span class="text-gray-700 dark:text-gray-400">Password</span>
                <input
                  v-bind:class="['border p-3 rounded outline-none block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input', { 'border-red-500': errors.password }]"
                  type="password"
                  name="password"
                  v-model="formData.password"
                />
                <p v-if="errors.password" class="text-red-500 text-xs mt-1">{{ errors.password }}</p>
              </label>

              <label class="block mt-4 text-sm">
                <span class="text-gray-700 dark:text-gray-400">Confirm Password</span>
                <input
                  v-bind:class="['border p-3 rounded outline-none block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input', { 'border-red-500': errors.confirmPassword }]"
                  type="password"
                  name="confirmPassword"
                  v-model="formData.confirmPassword"
                />
                <p v-if="errors.confirmPassword" class="text-red-500 text-xs mt-1">{{ errors.confirmPassword }}</p>
              </label>

              <button
                type="submit"
                class="flex items-center justify-center gap-2 w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-500 hover:bg-black focus:outline-none focus:shadow-outline-purple"
              >
                Sign Up
                <template v-if="loading">
                  <loaderVue />
                </template>
              </button>
            </form>
            <hr class="my-2" />
            <p class="mt-1">
              <a class="text-sm font-medium text-red-500 hover:underline hover:text-black" href="/sign-in">
                Already have an account? Login
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
	<footerv-2></footerv-2>
  </div>
</template>

<script>
import Header from '../components/home/Header.vue';
import Footer from '../components/home/Footer.vue';
import Breadcrum from '../components/Widgets/Breadcrum.vue';
import RegisterForm from '../components/forms/Register-Form.vue';
import TestForm from '../components/forms/TestForm.vue';
import Footerv2 from '../v2/Footerv2.vue';
import Headerv2 from '../v2/Headerv2.vue';
import loaderVue from '../components/loader.vue';
export default {
  data(){
    return{
      title: 'Reigister New Account',
      description: 'Our mission is to aid access to justice across Africa by linking verified legal professionals in the justice sector to the market',
      url: 'https://dialalawyer.africa',
      image: 'https://dialalawyer.africa/images/banner.jpg',
      formData: {
        role: 'lawfirm',
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
      },
      errors: {},
      loading:false
      };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
  // Open Graph (for Facebook, LinkedIn, etc.)
  { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description },
        { property: 'og:image', content: this.image },
        { property: 'og:url', content: this.url },

        // Twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: this.title },
        { name: 'twitter:description', content: this.description },
        { name: 'twitter:image', content: this.image },
        { name: 'twitter:url', content: this.url },
        // Add more tags as needed
      ]
    }
  },
  components:{Header,Footer,Breadcrum,RegisterForm, TestForm, Footerv2, Headerv2,loaderVue},
  methods: {
    validateForm() {
      const errors = {};
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!this.formData.role) {
        errors.role = 'Please select a role.';
      }

      if (this.formData.role === 'lawfirm' && !this.formData.companyName) {
        errors.companyName = 'Company name is required.';
      }

      if (this.formData.role === 'advocate') {
        if (!this.formData.firstName) {
          errors.firstName = 'First name is required.';
        }
        if (!this.formData.lastName) {
          errors.lastName = 'Last name is required.';
        }
      }

      if (!this.formData.email) {
        errors.email = 'Email is required.';
      } else if (!emailPattern.test(this.formData.email)) {
        errors.email = 'Invalid email address.';
      }

      if (!this.formData.password) {
        errors.password = 'Password is required.';
      } else if (this.formData.password.length < 6) {
        errors.password = 'Password must be at least 6 characters long.';
      }

      if (!this.formData.confirmPassword) {
        errors.confirmPassword = 'Confirm password is required.';
      } else if (this.formData.password !== this.formData.confirmPassword) {
        errors.confirmPassword = 'Passwords do not match.';
      }

      this.errors = errors;
      return Object.keys(errors).length === 0;
    },
    async handleSubmit() {
      if (!this.validateForm()) {
        return;
      }

      this.loading = true;

      try {
        // Handle form submission
        await this.signup(this.formData);
      } catch (error) {
        this.errors = error.response.data.errors || {};
      } finally {
        this.loading = false;
      }
    },
    signup(formData) {
      // Dummy signup function, replace with actual API call
      return new Promise((resolve, reject) => {
        console.log(formData);
        if(formData.role=='advocate'){
          this.$store.dispatch("signUp", formData);

        }else{
          this.$store.dispatch("registerFirm", formData)
        }
      });
    },
  },

};
</script>

<style lang="scss"></style>
