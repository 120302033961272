var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-login-section spad"},[_c('div',{staticClass:"container mx-auto"},[_c('div',{staticClass:"flex items-center justify-center"},[_c('div',{staticClass:"w-full md:w-1/2 px-10"},[_c('div',{staticClass:"register-form w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 p-3"},[_c('a-form',{staticClass:"login-form ",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"type":"flex","justify":"space-between","gutter":16}},[_c('a-col',{attrs:{"span":24,"sm":{ span: 24 }}},[_c('a-form-item',{staticClass:"group-input",attrs:{"label":"Name of Firm"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your  name of law firm!',
                      } ],
                  } ]),expression:"[\n                  'name',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please input your  name of law firm!',\n                      },\n                     \n                    ],\n                  },\n                ]"}],attrs:{"type":"text","id":"username","placeholder":"Your Name of Firm(required)"}})],1)],1)],1),_c('a-form-item',{staticClass:"group-input",attrs:{"label":"Official Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'email',
                  {
                    rules: [
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      {
                        required: true,
                        message: 'Please input your E-mail!',
                      } ],
                  } ]),expression:"[\n                  'email',\n                  {\n                    rules: [\n                      {\n                        type: 'email',\n                        message: 'The input is not valid E-mail!',\n                      },\n                      {\n                        required: true,\n                        message: 'Please input your E-mail!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"type":"text","placeholder":"Your Email(required)"}})],1),_c('a-form-item',{staticClass:"group-input",attrs:{"label":"Password"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'password',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                      {
                        min: 8,
                        message: 'Password must be 8 characters or more',
                      },
                      {
                        validator: _vm.validateToNextPassword,
                      } ],
                  } ]),expression:"[\n                  'password',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please input your password!',\n                      },\n                      {\n                        min: 8,\n                        message: 'Password must be 8 characters or more',\n                      },\n                      {\n                        validator: validateToNextPassword,\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"type":"password","placeholder":"Enter Password(required)"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{staticClass:"group-input",attrs:{"label":"Confirm Password"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'confirm',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      {
                        validator: _vm.compareToFirstPassword,
                      } ],
                  } ]),expression:"[\n                  'confirm',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please confirm your password!',\n                      },\n                      {\n                        validator: compareToFirstPassword,\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"id":"con-pass","type":"password","placeholder":"Re-Type Password"},on:{"blur":_vm.handleConfirmBlur}})],1),_c('button',{class:[_vm.loading?'site-btn-active disabled' : 'site-btn', 'register-btn'],attrs:{"type":"submit"}},[_vm._v(" REGISTER "),(_vm.loading)?_c('a-spin'):_vm._e()],1)],1),_c('div',{staticClass:"switch-login"},[_c('router-link',{staticClass:"or-login",attrs:{"to":"sign-in"}},[_vm._v("Or Login")])],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }